$base-icon-size: 28px;

@mixin host-material-icons($font-url: '../../fonts/icons') {
  $icon-link: $font-url + '/MaterialIcons-Regular';
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url($icon-link + '.eot');
    // scss-lint:disable SpaceAfterComma
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url($icon-link + '.woff2') format('woff2'),
    url($icon-link + '.woff') format('woff'),
    url($icon-link + '.ttf') format('truetype');
    // scss-lint:enable SpaceAfterComma
  }

  .material-icons {
    direction: ltr;
    display: inline-block;
    font-family: 'Material Icons';
    // Support for IE.
    font-feature-settings: 'liga';
    font-size: $base-icon-size;
    // Support for Firefox.
    -moz-osx-font-smoothing: grayscale;
    // Support for all WebKit browsers.
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1;
    // Support for Safari and Chrome.
    text-rendering: optimizeLegibility;
    text-transform: none;
    white-space: nowrap;
    word-wrap: normal;
  }
}

@include host-material-icons('../../fonts/icons');

.loading-icon {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.icon {
  display: inline-block;
  vertical-align: middle;

  &--pointer {
    cursor: pointer;
  }

  &--close {
    font-size: 14px;
    padding: 0.05em;
    color: #333;

    &:hover {
      color: #FFF;
      background: #333;
    }
  }

  &--reverse {
    transform: rotateY(180deg);
  }

  &--rounded {
    width: 0.833 * $base-icon-size;
    height: 0.833 * $base-icon-size;
    line-height: 0.833 * $base-icon-size !important;
    font-size: 0.6 * $base-icon-size !important;
    text-align: center;
    background: $primary;
    color: $tertiary;
    border-radius: 50%;

    &.icon--medium {
      width: $base-icon-size;
      height: $base-icon-size;
      line-height: $base-icon-size !important;
      font-size: 0.8 * $base-icon-size !important;
    }

    &.icon--large {
      width: 1.2 * $base-icon-size;
      height: 1.2 * $base-icon-size;
      line-height: 1.2 * $base-icon-size !important;
      font-size: 1 * $base-icon-size !important;
    }
  }

  &--spacing-right {
    margin-right: 5px;
  }

  &--primary {
    color: $primary;

    &.icon--rounded {
      color: $tertiary;
      background-color: $secondary;
      border: 2px solid $tertiary;
      transition: background-color $transition, color $transition;

      // dim bg on hover
      &:hover {
        background-color: $tertiary;
        color: $secondary;
      }
    }
  }

  &--secondary {
    color: $secondary;

    &.icon--rounded {
      color: #FFF;
      background-color: $secondary;

      // dim bg on hover
      &:hover {
        background-color: rgba($primary, 0.7);
      }
    }
  }

  &--ok {
    color: $ok;

    &.icon--rounded {
      color: #FFF;
      background-color: $ok;
      border: $icon-border;
      font-weight: bolder;
    }
  }

  &--logged-in {
    color: $blue;

    &.icon--rounded {
      color: #FFF;
      background-color: $blue;
      border: $icon-border;
    }
  }

  &--error {
    color: $error;

    &.icon--rounded {
      color: #FFF;
      background-color: $error;
      border: $icon-border;
      font-weight: bolder;
    }
  }

  &--in-progress {
    color: $yellow;

    &.icon--rounded {
      color: #FFF;
      background-color: $yellow;
      border: $icon-border;
      font-weight: bolder;
    }
  }


}