.details {
    width: 100%;
    border: 1px solid rgba($for-when-tertiary-fails, 0.5);
    box-sizing: border-box;

    .details-header {
        height: 51px;
        line-height: 51px;
        background-color: $header-alt;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        box-sizing: border-box;
    }

    .details-content {
        display: flex;
        box-sizing: border-box;
        background-color: $primary-alt;
        border-top: 1px solid rgba($for-when-tertiary-fails, 0.5);
        padding-right: 15px; // for scrolling

        &--hidden {
            display: none;
        }
    }

    .details-text {
        box-sizing: border-box;
        padding: 10px 45px;
        font-family: "Baskerville";
        background-color: $secondary;
    }

    .details-piece-text {
        line-height: 1.7;
        white-space: break-spaces;
    }

    .details-metrics {
        display: flex;
        border-left: 1px solid rgba($for-when-tertiary-fails, 0.5);
        padding: 8px;
        box-sizing: border-box;
    }
}

