@import url("https://fast.fonts.net/t/1.css?apiType=css&projectid=5835e2d3-307b-4233-b69b-fc4f93174765");

@font-face{
    font-family:"Frutiger"; // font-family:"Frutiger LT W01_45 Ligh1475730";
    src:url("/fonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot?#iefix");
    src:url("/fonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot?#iefix") format("eot"),url("/fonts/087d8a95-3f25-4bae-a1d1-db9ddffd72f5.woff2") format("woff2"),url("/fonts/ab1bcdf7-9570-4cba-a8e3-3e39ddd861db.woff") format("woff"),url("/fonts/83c94878-06c8-442c-a7df-d520b0ced00b.ttf") format("truetype");
    font-weight: 100;
}
@font-face{
    font-family:"Frutiger"; // font-family:"Frutiger LT W01_46 Ligh1475734";
    src:url("/fonts/381ea1e1-9a08-4929-a402-63be7fd76549.eot?#iefix");
    src:url("/fonts/381ea1e1-9a08-4929-a402-63be7fd76549.eot?#iefix") format("eot"),url("/fonts/db6ed4c5-5248-41f3-a6b5-4e8ff1d5ab06.woff2") format("woff2"),url("/fonts/38f88aba-a7d0-4a78-9323-ac58226d277e.woff") format("woff"),url("/fonts/1149176e-a9be-41bf-822e-275d9da199e5.ttf") format("truetype");
    font-weight: 100;
    font-style: italic;
}
@font-face{
    font-family:"Frutiger"; // font-family:"Frutiger LT W01_55 Roma1475738";
    src:url("/fonts/fe232554-5489-4874-bb45-bd966c8c5932.eot?#iefix");
    src:url("/fonts/fe232554-5489-4874-bb45-bd966c8c5932.eot?#iefix") format("eot"),url("/fonts/0811514e-6660-4043-92c1-23d0b8caaa2f.woff2") format("woff2"),url("/fonts/aeae8cac-ba4d-40d2-bbcf-936f2c7dcfe6.woff") format("woff"),url("/fonts/caedf2e6-fbef-4928-b08e-1f975981ae8d.ttf") format("truetype");
    font-weight: 400;
}
@font-face{
    font-family:"Frutiger"; // font-family:"Frutiger LT W01_56 Italic";
    src:url("/fonts/f8bbb037-530d-4e04-a651-4134f7031164.eot?#iefix");
    src:url("/fonts/f8bbb037-530d-4e04-a651-4134f7031164.eot?#iefix") format("eot"),url("/fonts/f5a95cb0-6d5b-4eb4-b6a3-a529d5ccde25.woff2") format("woff2"),url("/fonts/01f463dc-dba9-419f-9c9f-80ac2ee9f427.woff") format("woff"),url("/fonts/ba125970-2d75-413e-a466-324153755f20.ttf") format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face{
    font-family:"Frutiger"; // font-family:"Frutiger LT W01_65 Bold1475746";
    src:url("/fonts/eb06b4c3-c0fd-4cd0-a9d9-a2954f6839a7.eot?#iefix");
    src:url("/fonts/eb06b4c3-c0fd-4cd0-a9d9-a2954f6839a7.eot?#iefix") format("eot"),url("/fonts/8c92eb68-ce37-4962-a9f4-5b47d336bd1c.woff2") format("woff2"),url("/fonts/9bdfdcac-bbd4-46a9-9280-056c9a4438a2.woff") format("woff"),url("/fonts/5dae3a6e-9b07-4c2f-aed2-1f5b8951261b.ttf") format("truetype");
    font-weight: 700;
}
@font-face{
    font-family:"Frutiger"; // font-family:"Frutiger LT W01_66 Bold Italic";
    src:url("/fonts/36c26121-ef21-4e44-9f00-0388185c96aa.eot?#iefix");
    src:url("/fonts/36c26121-ef21-4e44-9f00-0388185c96aa.eot?#iefix") format("eot"),url("/fonts/9e60a7b4-389b-4630-9fa6-1abd9be2035f.woff2") format("woff2"),url("/fonts/3048913e-d0c8-4b82-978a-aa862248bea4.woff") format("woff"),url("/fonts/db4ea3f5-223b-4821-957f-6eb868db73b1.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face{
    font-family:"Frutiger"; // font-family:"Frutiger LT W01_75 Black";
    src:url("/fonts/fb1e4ad2-83fa-4735-aa1b-900f4a36749f.eot?#iefix");
    src:url("/fonts/fb1e4ad2-83fa-4735-aa1b-900f4a36749f.eot?#iefix") format("eot"),url("/fonts/221b3252-186e-4a32-a90b-cc0c3a4817d7.woff2") format("woff2"),url("/fonts/a061c7a6-3b14-451e-883c-0f1661ac3d76.woff") format("woff"),url("/fonts/558394f3-8981-4be9-8a8a-c23488eb9b6d.ttf") format("truetype");
    font-weight: 800;
}
@font-face{
    font-family:"Frutiger"; // font-family:"Frutiger LT W01_76 Blac1475758";
    src:url("/fonts/ad058bb8-7a08-4adb-81be-b97f0d00b951.eot?#iefix");
    src:url("/fonts/ad058bb8-7a08-4adb-81be-b97f0d00b951.eot?#iefix") format("eot"),url("/fonts/1ca1f0ee-8bf4-4a8f-9e47-f322036921a9.woff2") format("woff2"),url("/fonts/0d1f6923-8720-40fa-a8c9-5ba166ae2b98.woff") format("woff"),url("/fonts/61770e26-a01e-4032-83a6-32308bb7be52.ttf") format("truetype");
    font-weight: 800;
    font-style: italic;
}
@font-face{
    font-family:"Frutiger"; // font-family:"Frutiger LT W01_95 Ultra Black";
    src:url("/fonts/2c0f416b-892b-4223-b90e-a0986b832d52.eot?#iefix");
    src:url("/fonts/2c0f416b-892b-4223-b90e-a0986b832d52.eot?#iefix") format("eot"),url("/fonts/b3b6a774-8da3-4970-baa7-426572975164.woff2") format("woff2"),url("/fonts/145ceb9b-3d3e-4e3f-b5a8-9c9cf876cd0d.woff") format("woff"),url("/fonts/3fbefead-675a-4932-a670-f91da78ceece.ttf") format("truetype");
    font-weight: 900;
}
// Need to keep the modified names here as this is how they are referred in some SVGs
@font-face{
    font-family:"FrutigerLTPro-Roman";
    src:url("/fonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot?#iefix");
    src:url("/fonts/81e3653a-53bd-49d9-a1f8-f924811b6f02.eot?#iefix") format("eot"),url("/fonts/087d8a95-3f25-4bae-a1d1-db9ddffd72f5.woff2") format("woff2"),url("/fonts/ab1bcdf7-9570-4cba-a8e3-3e39ddd861db.woff") format("woff"),url("/fonts/83c94878-06c8-442c-a7df-d520b0ced00b.ttf") format("truetype");
    font-weight: 100;
}
@font-face{
    font-family:"FrutigerLTPro-Bold";
    src:url("/fonts/eb06b4c3-c0fd-4cd0-a9d9-a2954f6839a7.eot?#iefix");
    src:url("/fonts/eb06b4c3-c0fd-4cd0-a9d9-a2954f6839a7.eot?#iefix") format("eot"),url("/fonts/8c92eb68-ce37-4962-a9f4-5b47d336bd1c.woff2") format("woff2"),url("/fonts/9bdfdcac-bbd4-46a9-9280-056c9a4438a2.woff") format("woff"),url("/fonts/5dae3a6e-9b07-4c2f-aed2-1f5b8951261b.ttf") format("truetype");
    font-weight: bold;
}