.context-menu {
  background: #FFF;
  border: 1px solid #CCC;
  box-shadow: 3px 3px 3px #999;

  ul {
    margin:0;
    padding: 0;
  }

  &__item {
    display: block;
    list-style: none;
    margin: 0;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 14px;
    white-space: nowrap;
    position: relative;

    &:last-child {
      border-bottom: 0;
    }

    &:hover {
      background: lightblue;
      color: #FFF;
      cursor: pointer;
    }

    &--with-padding {
      padding: 5px 20px;
    }

    .material-icons {
      position: absolute;
      top: 4px;
      left: 2px;
      font-size: 16px;
      line-height: 16px;
      width: 16px;
      height: 16px;
    }
  }
}
