$dialog-padding: 20px;

.dialog {
  position: fixed;
  z-index: 110;
  cursor: auto;
  min-width: 280px;
  max-height: 100%;

  &--centered {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--text-centered {
    text-align: center;
  }

  &--paper {
    background: #FFF;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
  }

  &--100 {
    width: 100%;
  }

  &--large {
    width: 80%;
  }

  &--medium {
    width: 60%;
  }

  &--small {
    min-width: 300px;
    max-width: 400px;
    width: 30%;
  }

  &--20 {
    width: 20%;
  }
}

.dialog-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}

.dialog-heading {
  color: black;
  padding: $dialog-padding;
  h1, h2, h3 {
    margin: 0;
  }
}

.dialog-content {
  overflow: auto;
}

.dialog-content--padding {
  padding: $dialog-padding;
}

.dialog-actions {
  padding: $dialog-padding;
  padding-top: 0;
  text-align: right;
}