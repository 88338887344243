@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes blink {
  from {
    color: grey;
  }
  to {
    color: green;
  }
}

@keyframes blink-error {
  from {
    color: grey;
  }
  to {
    color: red;
  }
}