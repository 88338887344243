@font-face {
  font-family: "Baskerville";
  src: url("../../fonts/LibreBaskerville-Regular.ttf") format("truetype");
}

// font
$sm-line-height: 1.7;

// colourss
$active: lightblue;
$spelling-error: red;
$txt-speak: orange;
$proper: blue;
$proper-errors: pink;
$slang: #000000;
$saw: #0A246A;
$sacap: purple;
$punctuation: darken(yellow, 5%);
$specific: darkgreen;

.marker {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;

  &--info {
    float:left;
    display: inline-block;
  }

  &--text {
    line-height: $sm-line-height;
    margin: 0 0 17px;
    font-family: "Baskerville";
  }

  &--name {
    margin-right: 30px;
    font-size: 18px;
    font-weight: bold;
  }

  &__title {
    margin: 0 0 0.1rem 0;
    font-weight: normal;
    font-size: 1.6rem;
    text-align: center;
    padding-top: 10px;

    &--light {
      font-size: 1.4rem;
    }

    &--heavy {
      font-weight: bold;
    }

  }

  &__submission-actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 0;
    background-color: white;
    position: relative;

    &-right {
      text-align: right;
      .btn {
        margin-left: 10px;
      }
    }
  }

  &__submission-actions-assessment {
    display: flex;
    align-items: center;
    color: $for-when-tertiary-fails;
    font-weight: bold;
  }

  &__actions {
    width: 100%;
    padding: 0;
    background-color: $tertiary-alt;
    color: $secondary;

    &-right {
      text-align: right;


      .btn {
        margin-left: 10px;
      }
    }

    p {
      height: 35px;
      line-height: 35px;
      vertical-align: middle;
      margin: 0;
    }

    &__stopWatch {
      line-height: 35px;
    }
  }

  &__last-saved,
  &__saving {
    display: inline-block;
    height: 35px;
    line-height: 35px;
    vertical-align: middle;

    i {
      vertical-align: middle;
    }
  }

  &__columns {
    overflow: auto;
  }

  &__paragraph-container {
    width: 100%;
    padding-bottom: 20px;
    border-bottom: 1px solid #000;
  }

  &__column {
    height: 100%;
    max-height: 100%;
    box-sizing: border-box;
    padding: 10px 20px 0 20px;
    position: relative;

    > *:first-child {
      position: sticky;
      top: 0;
    }

    &--extra-padding {
      padding: 10px 45px 0;
    }

    &-close {
      position: absolute;
      right: 10px;
      top: 0;
      cursor: pointer;
    }

    &:first-child {
      background-color: #FFF;
      text-align: justify;
    }

    &:nth-child(2) {
      background-color: #eeeeee;
    }
  }

  &__text {
    &-paragraph {
      position: relative;
    }

    &-paragraph-marker {
      position: absolute;
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      color: #FFF;
      background-color: #555;
      left: -40px;
      text-align: center;
    }
  }

  &__paragraph {
    position: relative;

    &-marker {
      position: absolute;
      display: inline-block;
      width: 30px;
      height: 30px;
      line-height: 30px;
      border-radius: 50%;
      color: #FFF;
      background-color: #555;
      left: -19px;
      top: -8px;
      text-align: center;
    }
  }

  &__sentence {
    user-select: none;
    position: relative;
  }

  &__word {
    display: inline-block;
    cursor: pointer;
    margin-top: 2px;

    &--active {
      background-color: $active;
    }

    &--selected {
      color: #FFF;
      padding: 0 2px;
      //margin: 0 5px;
      display: inline-block;
    }

    &--spelling-error {
      //background-color: $spelling-error;
    }

    &--proper-noun {
      //background-color: $proper;
    }

    &--txt-speak {
      //background-color: $txt-speak;
    }

    &--slang {
      //background-color: $slang;
    }

    &--punctuation-error {
      //background-color: $punctuation;
    }

    &--proper-noun-error {
      //background-color: $proper-errors;
    }

    &--specific {
      //background-color: $specific;
    }

    &--also-specific {
      text-decoration: underline;
    }

    &--multi-error {
      position: relative;

      &:hover {
        .marker__word-multi-error-container {
          display: flex;
        }
      }
    }
  }

  &__word-multi-error-container {
    display: none;
    position: absolute;
    height: 16px;
    top: -16px;
    left: -1px;
  }

  &__word-multi-error-box {
    width: 16px;
    height: 16px;
  }

  // add background to words inside multi-word, word bg should override
  &__multi-word {
    &--proper-noun {
      .marker__word {
        //background-color: $proper;
        color: #FFF;
      }

      .marker__sentence-space {
        //background-color: $proper;

        //// remove bg on first space
        &:first-child {
          background: none;
        }
      }
    }

  }

  &__sentence-space {
    padding: 0 3px;
    display: inline-block;
    cursor: pointer;
    //text-decoration: underline;
    color: darken($primary, 3%);

    &--punctuation-error {
      background-color: darken($punctuation, 2%) !important;
    }

    &--active {
      background-color: $active;
    }
  }

  &__metric {
    display: inline-block;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    background-color: #0d384b;
    color: #FFF;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;

    &--saw {
      background-color: $saw;
    }

    &--saslang {
      background-color: $slang;
    }

    &--satxt {
      background-color: $txt-speak;
    }

    &--saspell {
      background-color: $spelling-error;
    }

    &--sacap {
      background-color: $sacap;
    }

    &--saproper {
      background-color: $proper;
    }

    &--sapne {
      background-color: $proper-errors;
    }

    &--sapunc {
      background-color: $punctuation;
    }

    &--saspec {
      background-color: $specific;
    }
  }

  &-notes {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 10;

    &__editor {
      width: 300px;
      border: 1px solid $primary;
      background-color: #FFF;
      padding: 20px;
      box-shadow: 2px 2px 2px #888;

      &--centered {
        position: fixed;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .textarea-field {
      height: 200px;
      width: 100%;
    }

    &__heading {
      margin: 0 0 10px 0;
      font-weight: normal;
    }
  }

  &__print {
    visibility: hidden;
    width: 0;
    height: 0;
    > div {
      display: none;
    }

    iframe {
      visibility: hidden;
      height: 0;
      width: 0;
      border: 0;
    }
  }

  &__overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: RGBA(12, 12, 12, 0.4);
    z-index: 19;
  }
}

// handle showing/hiding columns
.marker__column:first-child + .marker__column {
  border-left: 1px solid #3A3A3A;
}

.marker__column + .marker__column:last-child {
  border-left: 1px solid #3A3A3A;
}

.table-row--selected {
  &:nth-child(odd) {
    background-color: goldenrod !important;
  }
  &:nth-child(even) {
    background-color: lighten(goldenrod, 20) !important;
  }
}

.context-menu {
  &--submissions {
    margin-right: 11px;
  }
  &__container {
    &--submissions {
      position: absolute;
      left: unset;
      right: 0;
    }
  }
  &__item {
    &--submissions {
      &__text {
        display: table-cell;
        line-height: 18px;
        height: 22px;
        width: 100%;
      }
      &__icon {
        display: table-cell;
        height: 20px;
        text-align: left;
        padding-right: 15px;
        i {
          vertical-align: middle;
          font-size: 18px;
          line-height: 18px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.selected {
  box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.12);
  border: 2px solid $blue;
  background-color: #EEE;

}

