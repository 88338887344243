@import "../../node_modules/normalize.css/normalize.css";
@import "../../node_modules/react-md/src/scss/colors";

@import "../../node_modules/react-md/src/scss/globals";
@import "../../node_modules/react-md/src/scss/grids";

// these go first
@import "globals/variables";
@import "includes/keyframes";
@import "globals/alert";

@include react-md-grid;

// global elements includes
@import "globals/fonts";
@import "includes/buttons";
@import "includes/forms";
@import "includes/icons";
@import "includes/images";
@import "includes/login";
@import "includes/tables";
//@import "includes/material";
@import "includes/messages";
@import "includes/status-bar";
@import "includes/typography";

// global components
@import "includes/context-menu";
@import "components/dialog";

// manage components
@import "Mark/assessments";
@import "Mark/forms";
@import "Mark/submission-marker";
@import "Mark/print";
@import "Mark/details";

html, body {
  height: 100%;
  box-sizing: border-box;
}

#root {
  height: 100%;

  > div {
    height: 100%;
  }
}

.marker {
  &__container, &__grid {
    height: 100%;
  }
}

@import "includes/global";