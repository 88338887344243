.table {
  width: 100%;
  border-collapse: collapse;

  &-row {
    $this: &;
    height: 60px;
    background-color: $for-when-tertiary-fails;
    color: #FFF;

    &--extra {
      border-bottom: 1px $for-when-tertiary-fails solid;
      background-color: lighten($for-when-tertiary-fails, 20);
    }

    &--short {
      height: 40px;
    }

    &--even {
      background-color: $primary;
      color: $for-when-tertiary-fails;

      #{$this}--error {
        background-color: rgba(255, 0, 0, 0.2) !important;
      }

      #{$this}--ok {
        background-color: rgba($ok,0.2) !important;
      }

      #{$this}--warning {
        background-color: rgba($yellow, 0.2) !important;
      }

      #{$this}--info {
        background-color: rgba($blue, 0.2) !important;
      }
    }

    &--odd {
      background-color: $for-when-tertiary-fails;
      color: #FFF;

      #{$this}--error {
        background-color: rgba(255, 0, 0, 0.5) !important;
      }

      #{$this}--ok {
        background-color: rgba($ok,0.5) !important;
      }

      #{$this}--warning {
        background-color: rgba($yellow, 0.4) !important;
      }

      #{$this}--info {
        background-color: rgba($blue, 0.5) !important;
      }
    }


    &-actions {
      text-align: right;
      > i {
        margin-left: 5px;
      }
    }
  }

  &-cell {
    text-align: center;
    padding: 0 10px;

    &--header {
      padding-top: 40px;
      padding-bottom: 15px;
      color: $for-when-tertiary-fails;
      background-color: $secondary;
      border-bottom: 2px solid darken($for-when-tertiary-fails, 5%);
      vertical-align: bottom;
    }

    &--search-header {
      padding-bottom: 5px;
      padding-top: 5px;
      color: $for-when-tertiary-fails;
      background-color: goldenrod;
      border-bottom: 2px solid darken($for-when-tertiary-fails, 5%);

      .search-close {
        position: absolute;
        bottom: 10px;
        right: 0;
        font-size: 20px;
        color: white;
        cursor: pointer;
        transition: color ease-in-out 0.15s;

        &:hover {
          color: $tertiary;
        }
      }
    }

    &--right {
      text-align: right;
    }

    &--middle {
      text-align: center;
    }

    &--left {
      text-align: left;
    }

    &__search-text-area-container {
    }
  }
}