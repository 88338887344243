.message {
  padding: 20px;
  border: 2px solid transparent;

  &--error {
    border: 2px solid red;
    background: darkred;
    color: #FFF;
    margin-bottom: 20px;
  }

  &--global {
    position: fixed;
    right: 10px;
    top: 10px;
    background-color: #FFF;
    z-index: 20;
  }
}