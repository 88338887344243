$primary: #dcdddd;
$primary-alt: #eeeeee;
$secondary: white;
$tertiary: #1d8bdb;
$for-when-tertiary-fails: #939598;
$tertiary-alt: #add8e6;
$primary-font-color: #212121;
$border: #fff;
$box-shadow-colour: rgba(0, 0, 0, 0.3);
$box-shadow: 0 1px 3px 0 $box-shadow-colour;
$action-btn-background-color: $tertiary;
$action-btn-hover: #117ac6;
$action-btn-radius: 3px;
$sub-heading: #1d8bdb;
$selected-background: #e3f2fd;
$selected-border: 3px solid $tertiary;
$transition: 0.15s ease-in-out;
$main-radius: 3px;
$green: #00a651;
$yellow: #dce900;
$ok: #00a651;
$error: #ff175b;
$blue: #0079ff;
$icon-border: 2px solid white;
$gold: goldenrod;
$gold-light: lighten(goldenrod, 20);
$header-alt: #ade6bb;