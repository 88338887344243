@mixin border($width, $style, $color, $focus-colour) {
    border-width: $width;
    border-style: $style;
    border-color: $color;
    transition: border 1000ms ease-out;

    &:focus {
        border-color: $focus-colour;
    }
}

$input-font-size: 1rem;
$input-font-color: #000;
$input-height: 2.5rem;
$input-padding: 0.75rem 0.5rem;
$input-background-color: transparent;
$input-border-width: 0 0 1px 0;
$input-border-style: solid;
$input-border-color: #FFFFFF;
$input-border-color-white-bg: #CCCCCC;
$input-border-color-focus: purple;

.form {
    &__error {
        border: 2px solid darken($error, 2%);
        background-color: lighten($error, 20%);
        font-size: 1rem;
        color: #FFF;
        padding: 1rem;
        margin-bottom: 1rem;

        p {
            margin: 0;
        }
    }
}

.form-input {
    margin-bottom: 20px;
    position: relative;

    &:last-child {
        margin: 0;
    }

    &__label {
        width: 100%;
        display: block;
        font-size: $input-font-size * 0.7;
        color: purple;
    }
}

.checkbox-field__label {
    font-size: $input-font-size;
    color: #000;
    cursor: pointer;

    &:before {
        content: '\2717';
        display: inline-block;
        background-color: red;
        line-height: 25px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        vertical-align: center;
        color: #FFF;
        text-align: center;
        margin-right: 5px;
    }
}

input:checked + .checkbox-field__label {
    &:before {
        content: '\2713';
        background-color: green;
        color: #FFF;
    }
}

.checkbox-field {
    display: none;
}

.password-field, .text-field, .textarea-field {
    color: $input-font-color;
    background-color: $input-background-color;
    font-size: $input-font-size;
    padding: $input-padding;
    height: $input-height;
    @include border($input-border-width, $input-border-style, $input-border-color, $input-border-color-focus);
    width: 100%;
    box-sizing: border-box;
    outline: none;

    &--block {
        display: block;
        margin-bottom: 10px;
    }

    &--with-label {
        //width: 70%;
    }

    .form--white-bg & {
        @include border($input-border-width, $input-border-style, $input-border-color-white-bg, $input-border-color-focus);
    }
}

.textarea-field {
    height: auto;
    @include border(1px 1px 1px 1px, $input-border-style, $input-border-color, $input-border-color-focus);

    &--long {
        height: 200px;
    }

    &--white-bg {
        @include border(1px 1px 1px 1px, $input-border-style, $input-border-color-white-bg, $input-border-color-focus);
        background-color: white;
    }
}

.select-field {
    background: linear-gradient(45deg, transparent 50%, $tertiary 50%) no-repeat calc(100% - 10px) 10px,
    linear-gradient(135deg, $tertiary 50%, transparent 50%) no-repeat 100% 10px;
    background-size: 10px 10px,
    10px 10px;
    line-height: 1.5em;
    padding-right: 40px;
    height: $input-height;
    font-size: $input-font-size;
    font-family: "Roboto", sans-serif;
    @include border($input-border-width, $input-border-style, $input-border-color, $input-border-color-focus);
    border-radius: 0;
    color: $input-font-color;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    position: relative;

    option {
        background-color: $primary;
        color: black;
    }

    &:focus {
        background: linear-gradient(45deg, $tertiary 50%, transparent 50%) no-repeat 100% 10px,
        linear-gradient(135deg, transparent 50%, $tertiary 50%) no-repeat calc(100% - 10px) 10px;
        background-size: 10px 10px,
        10px 10px;
    }
}

.select-field-dropdown {
    background-color: white;
    box-shadow: $box-shadow;
}

.select-field-dropdown-item {
    width: 100%;
    height: 2rem;
    padding: 8px 22px;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
        background-color: $primary-alt;
    }
}

.select-field-overlay {
    display: none;

    &--show {
        position: fixed;
        z-index: 1;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.form-actions, .form__actions {

    button {
        margin: 0 5px 0 0;
    }

    &--align-right {
        text-align: right;

        button {
            margin: 0 0 0 5px;
        }
    }
}

.field-group {
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #000;

    &:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
        border: 0;
    }
}
