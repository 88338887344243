.marker {
  .form-input {
    margin-bottom: 5px;

    &__label {
      width: 70%;
      display: inline-block;
      font-size: $input-font-size * 1;
      color: purple;
    }
  }

  .text-field {

    &--with-label {
      width: 30%;
    }
  }

  .checkbox-field {
    &__label {
      width: auto;
    }
  }
}