$btn-transition-time: 0.3s;
$btn-default-bg-color: #333 !default;
$btn-default-text-color: #FFF !default;
$btn-padding-x: 0.75rem !default;
$btn-padding-y: 0.5rem !default;

@mixin button-colors($bg-color, $text-color) {
  background-color: $bg-color;
  color: $text-color;

  &:hover {
    background-color: darken($bg-color, 7%);
  }
}

.btn {
  display: inline-block;
  font-size: 1rem;
  background: transparent;
  border: 0;
  position: relative;
  transition-duration: $btn-transition-time;
  transition-property: background, color;
  padding: $btn-padding-y $btn-padding-x;
  border-radius: 3px;

  @include button-colors($btn-default-bg-color, $btn-default-text-color);

  // Prevents click event from firing when clicking a child
  &[disabled] * {
    pointer-events: none;
  }

  &:focus {
    outline-style: none;
  }

  &--primary {
    @include button-colors($primary, #FFF);
  }

  &--secondary {
    @include button-colors($secondary, #FFF);
  }

  &--tertiary {
    @include button-colors($tertiary, #FFF);
  }

  &--info {
    @include button-colors($tertiary, #FFF);
  }

  &--disabled {
    @include button-colors($secondary, #FFF);
  }

  &--ok {
    @include button-colors($tertiary, #FFF);
  }

  &--green {
    @include button-colors($ok, #FFF);
  }

  &--error {
    @include button-colors($error, #FFF);
  }

  &--gold {
    @include button-colors($gold, #FFF);
  }

  &--spacing-right {
    margin-right: 10px;
  }

  //icon
  > i {
    font-size: 1.2rem;
    line-height: 1;
    vertical-align: middle;
    font-weight: bold;
    margin-left: 5px;
  }
}

a.btn {
  text-decoration: none;
}

