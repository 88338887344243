.status-bar {
  position: fixed;
  left: 0;
  top: 0;
  text-align: right;
  width: 100%;
  padding: 5px 10px;

  i {
    font-size: 40px;
  }

  &__title {
    float: left;
  }

  &__offline {
    animation-name: blink-error;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }

  &__saving {
    animation-name: blink;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}