@media print {
	@page {
		margin: 0;
	}
	.marker {
		&__print {
			&__page {
				font-family: "Helvetica";
				position: relative;
				height: 100%;
				width: 100%;
				box-sizing: border-box;
				padding: 80px;
				word-wrap: break-word;
				P {
					line-height: 1.7;
					font-size: 1.1rem;
				}
				&__header {
					position: absolute;
					top: 0;
					right: 0;
					margin: 20px 40px 0 0;
					font-size: 0.8rem !important;
					font-weight: normal;
				}
				&:not(:last-of-type) {
					page-break-after: always;
				}

			}
		}
	}
}