html {
	background-color: $primary;
}

body {
	font-family: "Frutiger", "helvetica neue", helvetica, arial, sans-serif;
	font-size: 16px;
}

input, select, button {
	&:focus {
		outline: none;
	}
}

button {
	cursor: pointer;
}