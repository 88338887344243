.marker {
  &__marker-dialog {
    height: 300px;
    overflow: auto;
    transition: border 0.2s;
    margin-bottom: 20px;

    &__list {
      margin: 0;
      list-style-type: none;
      padding-left: 0;
    }

    &__item {
      text-align: left;
      transition: background-color 0.1s;

      &:hover {
        background-color: $primary;
      }

      .form-input {
        margin: 0;
        padding: 10px 0;
      }

      .checkbox-field__label:before {
        margin: 0 10px;
      }

    }
  }
  &__assessment-actions {
    padding: 0;
    background-color: white;
  }
  &__assessment-actions-right {
    text-align: right;
    float: right;
    .btn {
      margin-left: 10px;
    }
  }
  &__actions {
    padding: 0;
    background-color: lightblue;
    color: #FFF;

    &-right {
      text-align: right;

      .btn {
        margin-left: 10px;
      }
    }
  }
}