.login {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0, 0.1);
  z-index: 1000;

  &__loading {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    > i {
      font-size: 80px;
      color: $secondary;
      animation-name: spin;
      animation-duration: 2000ms;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }

  &__error {
    &__text-centered {
      text-align: center;
    }
  }
}