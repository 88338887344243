.centered {
  margin: 0 auto;
}

.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.max-content-size {
  max-width: 1280px;
}

.text {
  &--centered {
    text-align: center;
  }
}

.modal-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0;
  left: 0;
  top: 0;
}
