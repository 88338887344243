.alert {
	width: 100%;
	padding: 15px;
	margin-bottom: 20px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	border: 1px solid transparent;

	&-warning {
		color: darken($yellow, 25%);
		background-color: rgba($yellow, 0.2);
		border-color: rgba(darken($yellow, 20%), 0.2);
	}

	&-info {
		color: darken($blue, 25%);
		background-color: rgba($blue, 0.2);
		border-color: rgba(darken($blue, 20%), 0.2);
	}

	&-success {
		color: darken($ok, 15%);
		background-color: rgba($ok, 0.2);
		border-color: rgba(darken($ok, 20%), 0.2);
	}

	&-error {
		color: darken($error, 30%);
		background-color: rgba($error, 0.2);
		border-color: rgba(darken($error, 20%), 0.2);
	}

	&--no-margin {
		margin: 0;
	}
}